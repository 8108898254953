export default {
  cmc: {
    bgColor: '#FACA67',
    fontColor: '#1f1f1f',
    highlightColor: '#FACA67',
    copy: {
      pt: 'Obrigado por nos visitar e uma boa BTL 2024.',
      en: 'Thank you for visiting us and have a good BTL 2024.'
    }
  },
  fbb: {
    bgColor: '#F6C555',
    fontColor: '#1f1f1f',
    highlightColor: '#F6C555',
    copy: {
      pt: 'Obrigado por nos visitar e uma boa BTL 2024.',
      en: 'Thank you for visiting us and have a good BTL 2024.'
    }
  },
  uc: {
    bgColor: '#E38348',
    fontColor: '#1f1f1f',
    highlightColor: '#E38348',
    copy: {
      pt: 'Obrigado por nos visitar e uma boa BTL 2024.',
      en: 'Thank you for visiting us and have a good BTL 2024.'
    }
  },
};
