import { useEffect, useContext } from 'react';

import { AppContext } from '../App';

// import Webcam from 'webcam-easy';

export default function WebcamVideo(props) {
  const { brand, setBrand } = useContext(AppContext);

  useEffect(() => {
    const webcamElement = document.getElementById('video');
    const canvasElement = document.getElementById('canvas');
    // const webcam = new Webcam(webcamElement, 'user', canvasElement);


    navigator.mediaDevices.getUserMedia({video: true, audio: false}).then(stream => {
      console.log(stream.getVideoTracks()[0].getSettings());
      props.rollbar.info('stream new js getSettings', {
        width: stream.getVideoTracks()[0].getSettings().width,
        height: stream.getVideoTracks()[0].getSettings().height,
        resizeMode: stream.getVideoTracks()[0].getSettings().resizeMode,
      });

      webcamElement.width = stream.getVideoTracks()[0].getSettings().width ;
      webcamElement.height = stream.getVideoTracks()[0].getSettings().height ;
      canvasElement.width = stream.getVideoTracks()[0].getSettings().width ;
      canvasElement.height = stream.getVideoTracks()[0].getSettings().height ;
      webcamElement.srcObject = stream;
      webcamElement.play();

console.log( webcamElement.getBoundingClientRect().width, webcamElement.getBoundingClientRect().height);

    });

    // webcam.start()
    //  .then(result => {

        // props.rollbar.info('stream - getSettings', {
        //   stream1: {
        //     width: webcam._streamList[0]?.getTracks()[0].getSettings().width,
        //     height: webcam._streamList[0]?.getTracks()[0].getSettings().height,
        //     aspectRatio: webcam._streamList[0]?.getTracks()[0].getSettings().aspectRatio,
        //     // frameRate: webcam._streamList[0]?.getTracks()[0].getSettings().frameRate,
        //     // resizeMode: webcam._streamList[0]?.getTracks()[0].getSettings().resizeMode,
        //     deviceId: webcam._streamList[0]?.getTracks()[0].getSettings().deviceId
        //   },
        //   stream2: {
        //     width: webcam._streamList[1]?.getTracks()[0].getSettings().width,
        //     height: webcam._streamList[1]?.getTracks()[0].getSettings().height,
        //     aspectRatio: webcam._streamList[1]?.getTracks()[0].getSettings().aspectRatio,
        //     // frameRate: webcam._streamList[1]?.getTracks()[0].getSettings().frameRate,
        //     // resizeMode: webcam._streamList[1]?.getTracks()[0].getSettings().resizeMode,
        //     deviceId: webcam._streamList[1]?.getTracks()[0].getSettings().deviceId
        //   },
        // });

     //    webcamElement.width = webcam._streamList[1]?.getTracks()[0].getSettings().width;
     //    webcamElement.height = webcam._streamList[1]?.getTracks()[0].getSettings().height;

     //    canvasElement.width = webcam._streamList[1]?.getTracks()[0].getSettings().width;
     //    canvasElement.height = webcam._streamList[1]?.getTracks()[0].getSettings().height;
     // })
     // .catch(err => {
     //     console.log(err);
     // });
  }, [props.rollbar])

  return <>
    <video autoPlay={true} playsInline={true} muted={true} id="video" />
    <canvas id="canvas" />
  </>;
}
