import { useContext } from 'react';

import { AppContext } from '../App';

import cmcLogo from '/assets/img/cmc-logo-color.png';
import fbbLogo from '/assets/img/fbb-logo.png';
import ucLogo from '/assets/img/uc-logo.png';

import BrandAssets from '../utils/BrandAssets.js';

export default function BrandSelector() {
  const { brand, setBrand } = useContext(AppContext);

  document.documentElement.style.setProperty('--bg-color', BrandAssets[brand].bgColor);
  document.documentElement.style.setProperty('--font-color', BrandAssets[brand].fontColor);
  document.documentElement.style.setProperty('--highlight-color', BrandAssets[brand].highlightColor);

  return (
    <div className="brand-selector">
      <div className={brand === 'cmc' ? 'brand-selector__option brand-selector__option--active' : 'brand-selector__option'}>
        <img onClick={ () => setBrand('cmc') } src={ cmcLogo } />
      </div>
      <div className={brand === 'uc' ? 'brand-selector__option brand-selector__option--active' : 'brand-selector__option'}>
        <img onClick={ () => setBrand('uc') } src={ ucLogo } />
      </div>
      <div className={brand === 'fbb' ? 'brand-selector__option brand-selector__option--active' : 'brand-selector__option'}>
        <img onClick={ () => setBrand('fbb') } src={ fbbLogo } />
      </div>
    </div>
  );
}
