import PhotoBoothButtons from '../components/PhotoBoothButtons';
import WebcamVideo from '../components/WebcamVideo';
import FrameImg from '../components/FrameImg';


import Rollbar from 'rollbar';
import { Provider } from '@rollbar/react';

const rollbarConfig = {
  accessToken: 'd02b1b6ecaac4ce4ab5e3a774169113d',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'staging',
    // context: 'rollbar/test'
    client: {
      javascript: {
        code_version: '1.0',
        // source_map_enabled: true,
        // guess_uncaught_frames: true
      }
    }
  }
};
const rollbar = new Rollbar(rollbarConfig);



export default function HomeScreen() {
  return (
    <Provider instance={rollbar}>
      <div className="cover-container">
        <FrameImg />
        <WebcamVideo rollbar={rollbar} />
      </div>
      <PhotoBoothButtons />
    </Provider>
  );
}
