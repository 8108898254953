import { useContext } from 'react';

import { AppContext } from '../App';

import WebcamVideo from '../components/WebcamVideo';
import FrameImg from '../components/FrameImg';
import BrandSelector from '../components/BrandSelector';
import LanguageSelector from '../components/LanguageSelector';

import frameImgCmc from '/assets/img/frame-cmc.png';
import frameImgFbb from '/assets/img/frame-fbb.png';
import frameImgUc from '/assets/img/frame-uc.png';


import Rollbar from 'rollbar';
import { Provider } from '@rollbar/react';

const rollbarConfig = {
  accessToken: 'd02b1b6ecaac4ce4ab5e3a774169113d',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'staging',
    // context: 'rollbar/test'
    client: {
      javascript: {
        code_version: '1.0',
        // source_map_enabled: true,
        // guess_uncaught_frames: true
      }
    }
  }
};
const rollbar = new Rollbar(rollbarConfig);


export default function HomeScreen(props) {
  const { screen, setScreen } = useContext(AppContext);
  const { lang, setLang } = useContext(AppContext);

  return (
    <Provider instance={rollbar}>
      <div className="cover-container">
        <FrameImg />
        <WebcamVideo rollbar={rollbar} />
      </div>
      <LanguageSelector />

      <div className="bottom-container bottom-container--home">
        <div className="floating-button-bar">
          <button
            className="primary"
            onClick={() => {
              setScreen('photo-booth')
            }}
          >
            <span className="material-symbols-outlined">photo_camera</span>
            &nbsp;{lang == 'pt' ? 'Guarde este momento' : 'Save this moment'}
          </button>
        </div>
        <p className="bottom-container__text">{lang == 'pt' ? 'Escolha uma moldura:' : 'Choose a frame:'}</p>
        <BrandSelector />
      </div>
    </Provider>
  );
}
