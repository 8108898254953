import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import KioskBoard from 'kioskboard';
import html2canvas from 'html2canvas';

import FrameImg from '../components/FrameImg';

import { AppContext } from '../App';

import BrandAssets from '../utils/BrandAssets.js';

export default function PhotoFinishScreen() {

  const { screen, photoDataUrl, setScreen, brand, lang } = useContext(AppContext);
  const [ modalIsOpen, setModalOpen ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ sendingEmail, setSendingEmail ] = useState(null);
  const [ sentEmail, setSentEmail ] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  useEffect(() => {
    KioskBoard.init({
      keysArrayOfObjects: [
       {
          "0": "Q",
          "1": "W",
          "2": "E",
          "3": "R",
          "4": "T",
          "5": "Y",
          "6": "U",
          "7": "I",
          "8": "O",
          "9": "P"
       },
       {
          "0": "A",
          "1": "S",
          "2": "D",
          "3": "F",
          "4": "G",
          "5": "H",
          "6": "J",
          "7": "K",
          "8": "L",
          "9": "@"
       },
       {
          "0": "Z",
          "1": "X",
          "2": "C",
          "3": "V",
          "4": "B",
          "5": "N",
          "6": "M",
          "7": ".",
          "8": "_"
       }
      ],
      keysSpacebarText: 'Espaço',
      keysFontFamily: 'Roboto',
      keysFontSize: '1em',
      keysFontWeight: 'bold',
      theme: 'flat',
      capsLockActive: false
    });
  });

  return (
    <>
      <div className="cover-container">
        <FrameImg />
        <img id="photo" src={photoDataUrl} />
      </div>
      <div className="bottom-container">
        <div className="floating-button-bar">
          <button onClick={() => {
            setScreen('photo-booth');
          }}>
            <span className="material-symbols-outlined">photo_camera</span>
            &nbsp;{lang == 'pt' ? 'Repetir foto' : 'Repeat photo'}
          </button>
          <button
            className="primary"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <span className="material-symbols-outlined">forward_to_inbox</span>
            &nbsp;{lang == 'pt' ? 'Enviar' : 'Send'}
          </button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.75)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 5,
          },
          content: {
            backgroundColor: 'var(--bg-color)',
            borderRadius: '60px',
            border: '10px solid var(--font-color)',
            padding: '60px',
            inset: 'initial',
            margin: '10%'
          }
        }}
        onAfterOpen={() => {
          setError(null);
          setSentEmail(null);
          setSendingEmail(null);
          KioskBoard.run('.js-virtual-keyboard');
        }}
      >
        { sentEmail ?
          <>
            <p>{lang == 'pt' ? 'A sua fotografia foi enviada. Partilhe com os seus amigos!' : 'Your photo has been sent. Share with your friends!'}</p>
            <p>{BrandAssets[brand].copy[lang]}</p>
            <p
              style={{
                marginBottom: '1.5em'
              }}
            >
            </p>
            <div style={{
              textAlign: 'center'
            }}>
              <button
                className="primary"
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                {lang == 'pt' ? 'Cancelar' : 'Cancel'}
              </button>
              <button
                onClick={() => {
                  setScreen('home');
                }}
                style={{
                  marginLeft: '0.1em'
                }}
              >
                <span className="material-symbols-outlined">home</span>
                &nbsp;{lang == 'pt' ? 'Voltar ao início' : 'Back to home'}
              </button>
            </div>
          </>
          :
          <>
            <p>{lang == 'pt' ? 'Receba esta fotografia no seu email e partilhe nas suas redes sociais.' : 'Receive this photo in your email and share it on your social networks.'}</p>
            <input
              className="js-virtual-keyboard"
              type="text"
              placeholder="E-MAIL"
              style={{
                marginBottom: '1.5em'
              }}
              data-kioskboard-type="all"
              data-kioskboard-placement="bottom"
              data-kioskboard-specialcharacters="true"
            />
            <div style={{
              textAlign: 'center'
            }}>
              <button
                disabled={sendingEmail}
                onClick={async () => {
                  const email = document.querySelector('.js-virtual-keyboard').value;

                  if (!isValidEmail(email))
                    setError(lang == 'pt' ? 'É necessário introduzir um e-mail válido.' : 'You must enter a valid email address.');
                  else {
                    setError(null);
                    setSendingEmail(true);

                    let canvas = await html2canvas(document.querySelector('.cover-container'));

                    const response = await fetch(process.env.API_BASE_URL + '/send-email', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        email,
                        campaign: process.env.NODE_ENV !== 'production' ? 'development-'+brand+'-'+lang : brand+'-'+lang,
                        dataUrl: canvas.toDataURL('image/jpeg', 0.92)
                      })
                    });

                    setSendingEmail(false);

                    if(response.ok)
                      setSentEmail(true);
                    else {
                      setError(lang == 'pt' ? 'Oops, ocorreu um erro. Tentamos novamente?' : 'Oops, an error occurred. Shall we try again?');
                    }
                  }
                }}
              >
                { sendingEmail ? (lang == 'pt' ? 'A enviar Fotografia...' : 'Sending Photo...') : (lang == 'pt' ? 'Enviar Fotografia' : 'Send Photo') }
              </button>
            </div>
            {error && <p style={{color: '#D10000'}}>{error}</p>}
            <p style={{
              opacity: '0.5'
            }}>
              {lang == 'pt'
                ? 'Ao continuar aceita o tratamento dos seus dados pessoais para comunicações de Marketing da Câmara Municipal de Coimbra, da Universidade de Coimbra e da Fundação Bissaya Barreto.'
                : 'By continuing, you accept the processing of your personal data for marketing communications from the Municipality of Coimbra, the University of Coimbra, and the Bissaya Barreto Foundation.'}
            </p>
            <div style={{
              textAlign: 'center'
            }}>
              <button
                className="primary"
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                {lang == 'pt' ? 'Cancelar' : 'Cancel'}
              </button>
            </div>
          </>
        }


      </Modal>
    </>
  );
}
