import { useContext } from 'react';

import { AppContext } from '../App';

export default function BrandSelector() {
  const { lang, setLang } = useContext(AppContext);

  return (
    <div className="language-selector">
      <button className={lang === 'pt' ? 'primary selected' : 'primary'} onClick={ () => setLang('pt') } >PT</button>
      <button className={lang === 'en' ? 'primary selected' : 'primary'} onClick={ () => setLang('en') } >EN</button>
    </div>
  );
}
