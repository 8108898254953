import { useContext } from 'react';

import { AppContext } from '../App';

import frameImgCmc from '/assets/img/frame-cmc.png';
import frameImgFbb from '/assets/img/frame-fbb.png';
import frameImgUc from '/assets/img/frame-uc.png';

export default function FrameImg() {
  const frameImg = {
    cmc: frameImgCmc,
    fbb: frameImgFbb,
    uc: frameImgUc,
  };
  const { brand, setBrand } = useContext(AppContext);

  return (
    <img id="frame" src={ frameImg[brand] } />
  );
}
