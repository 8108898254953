import { useContext, useState } from 'react';
import { AppContext } from '../App';

// import Webcam from 'webcam-easy';
export default function PhotoBoothButtons() {

  const context = useContext(AppContext);
  const [ countdown, setCountdown ] = useState();

  function takePhoto () {
    setCountdown(9);
    setTimeout(() => setCountdown(8), 1000);
    setTimeout(() => setCountdown(7), 2000);
    setTimeout(() => setCountdown(6), 3000);
    setTimeout(() => setCountdown(5), 4000);
    setTimeout(() => setCountdown(4), 5000);
    setTimeout(() => setCountdown(3), 6000);
    setTimeout(() => setCountdown(2), 7000);
    setTimeout(() => setCountdown(1), 8000);
    setTimeout(() => {
      const webcamElement = document.getElementById('video');
      const canvasElement = document.getElementById('canvas');
      const canvasContext = canvasElement.getContext('2d');
      // const webcam = new Webcam(webcamElement, 'user', canvasElement);

      // const newImage = new Image();
      // const picture = webcam.snap();

      // canvasContext.drawImage(newImage, 0, 0, webcamElement.getBoundingClientRect().width, webcamElement.getBoundingClientRect().height );

      // newImage.src = picture;

      canvasContext.drawImage(webcamElement, 0, 0);


      context.setContext({
        ...context,
        // photoDataUrl: canvasElement.toDataURL('image/jpeg', 0.98),
        photoDataUrl: canvasElement.toDataURL('image/jpeg', 0.98),
        screen: 'photo-finish'
      })
    }, 9000);
  }

  return (
    <div className="bottom-container">
      { countdown ?
        <>
          <div className="camera-mark"><span className="material-symbols-outlined">arrow_circle_up</span></div>
          <div className="countdown">{countdown}</div>

          <div className="floating-button-bar">
            <button disabled>
              <span className="material-symbols-outlined">photo_camera</span>
              &nbsp;{context.lang == 'pt' ? 'Sorria para a foto' : 'Smile for the photo'}
            </button>
          </div>
        </>
        :
        <div className="floating-button-bar">
          <button onClick={() => {
            context.setScreen('home');
          }}>
            {context.lang == 'pt' ? 'Cancelar' : 'Cancel'}
          </button>
          <button
            className="primary"
            onClick={takePhoto}
          >
            <span className="material-symbols-outlined">photo_camera</span>
            &nbsp;{context.lang == 'pt' ? 'Tirar foto' : 'Take a photo'}
          </button>
        </div>
      }
    </div>
  );
}
