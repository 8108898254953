import React, { useContext, useState } from 'react';
import Modal from 'react-modal';

import HomeScreen from './screens/HomeScreen';
import PhotoBoothScreen from './screens/PhotoBoothScreen';
import PhotoFinishScreen from './screens/PhotoFinishScreen';

export const AppContext = React.createContext();

Modal.setAppElement('#root');

export function App() {

  const [ context, setContext ] = useState({
    screen: 'home',
    brand: 'cmc',
    lang: 'pt'
  });

  return (
    <AppContext.Provider value={{
      ...context,
      setScreen: (screen) => {
        setContext({
          ...context,
          screen
        });
      },
      setBrand: (brand) => {
        setContext({
          ...context,
          brand
        });
      },
      setLang: (lang) => {
        setContext({
          ...context,
          lang
        });
      },
      setContext
    }}>
      { context.screen == 'home' && <HomeScreen/> }
      { context.screen == 'photo-booth' && <PhotoBoothScreen/> }
      { context.screen == 'photo-finish' && <PhotoFinishScreen/> }
    </AppContext.Provider>
  );
}
